@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply font-montserrat;
  }
}
.shrink-header {
  @apply h-[4.5rem] bg-dark;
}

.active {
  @apply after:!w-full;
}

.dropdown-transistion {
  opacity: 0;
  transform: translateY(-100px);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.content-list .swiper-slide {
  @apply w-[40%] md:w-[30%] lg:w-[15%];
}
